import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { createSlice } from '@reduxjs/toolkit'
import { getTimezoneOffset, getStartOrEndTimeOfDate } from '@features/General/dayjs/hanlers'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { IDataState } from '@typings/requestPayloads'
import { listUIReducers } from '../reducers'
dayjs.extend(timezone)

const meetingsInitialState: IDataState = {
    ...listUIInitialState,
    preLoading: true,
    filters: {
        status: ['Scheduled'],
        startTime: { $gte: getStartOrEndTimeOfDate() }
    },
    pureFilters: {
        status: ['Scheduled'],
        meetingDate: [dayjs(), null],
        timeShift: '',
        eventTimeShift: ''
    },
    sort: { startTime: 1, _id: -1 }
}
const meetingAgendaSlice = createSlice({
    name: 'ui/meeting-agenda',
    initialState: meetingsInitialState,
    reducers: {
        ...listUIReducers,
        onChangePreLoading(state, action) {
            state.preLoading = action.payload
        },
        onChangeData(state, action) {
            Object.keys(action.payload).map(key => {
                if (key === 'pureFilters') {
                    state.pureFilters = {
                        eventTimeShift: state.pureFilters.eventTimeShift,
                        timeShift: state.pureFilters.eventTimeShift || getTimezoneOffset(dayjs.tz.guess()),
                        ...action.payload[key]
                    }
                } else {
                    state[key] = action.payload[key]
                }
            })
        },
        addPureFilter(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state.pureFilters[key] = action.payload[key]
            }
        },
        onResetFilters(state, action) {
            state.filters = {}
            state.pureFilters = {
                eventTimeShift: action.payload?.eventTimeShift || state.pureFilters.eventTimeShift,
                timeShift:
                    action.payload?.timeShift || state.pureFilters.eventTimeShift || getTimezoneOffset(dayjs.tz.guess())
            }
        }
    }
})

export const meetingAgendaActions = meetingAgendaSlice.actions
export default meetingAgendaSlice.reducer
