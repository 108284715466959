import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { getTimezoneOffset, getStartOrEndTimeOfDate } from '@features/General/dayjs/hanlers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { listUIReducers } from '../reducers'
dayjs.extend(timezone)

export type MeetingRequestsInfoModalTypes = {
    isOpen?: boolean
    modalState?: 'ACCEPT' | 'CONFIRM' | 'REJECT'
    requestInfo?: any
}

export interface IMeetingRequestsListDataState {
    infoModal: MeetingRequestsInfoModalTypes
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    populates?: any
    pureFilters?: any
}

const initialState: IMeetingRequestsListDataState = {
    infoModal: {
        isOpen: false,
        modalState: 'ACCEPT',
        requestInfo: null
    },
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    sort: '-createdAt',
    needPopulate: true,
    populates: [
        { path: 'location', select: 'name' },
        {
            path: 'hostId',
            select: '_id company avatar firstname lastname email activityLogs jobTitle mobile details linkedin twitter'
        },
        {
            path: 'contactIds',
            select: '_id company avatar firstname lastname email activityLogs jobTitle mobile details linkedin twitter'
        },
        { path: 'updatedBy', select: 'firstname lastname' },
        { path: 'hostCompanyId', select: 'name logo _id' },
        { path: 'guestCompanyId', select: 'name logo _id' }
    ],
    filters: {
        status: ['Scheduled'],
        startTime: { $gte: getStartOrEndTimeOfDate() }
    },
    pureFilters: {
        status: ['Scheduled'],
        meetingDate: [dayjs(), null],
        timeShift: '',
        eventTimeShift: ''
    },
    projection: []
}

const meetingRequestsListSlice = createSlice({
    name: 'ui/meeting-requests-list',
    initialState,
    reducers: {
        ...listUIReducers,
        changeInfoModal(state, action: PayloadAction<MeetingRequestsInfoModalTypes>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.infoModal[item] = action.payload[item]
            }
        },
        resetInfoModal(state) {
            state.infoModal = initialState.infoModal
        },
        onChangeFilters(state, action) {
            state.filters = action.payload
        },
        onChangeData(state, action) {
            Object.keys(action.payload).map(key => {
                if (key === 'pureFilters') {
                    state.pureFilters = {
                        eventTimeShift: state.pureFilters.eventTimeShift,
                        timeShift: state.pureFilters.eventTimeShift || getTimezoneOffset(dayjs.tz.guess()),
                        ...action.payload[key]
                    }
                } else {
                    state[key] = action.payload[key]
                }
            })
        },
        addPureFilter(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state.pureFilters[key] = action.payload[key]
            }
        },
        onResetFilters(state, action) {
            state.filters = {}
            state.pureFilters = {
                eventTimeShift: action.payload?.eventTimeShift || state.pureFilters.eventTimeShift,
                timeShift:
                    action.payload?.timeShift || state.pureFilters.eventTimeShift || getTimezoneOffset(dayjs.tz.guess())
            }
        }
    }
})

export const meetingRequestsListActions = meetingRequestsListSlice.actions
export default meetingRequestsListSlice.reducer
