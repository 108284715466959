export * from './list'
export const durations = [15, 30, 45, 60]
export const durationsAvailableCalendar = [15, 30, 45, 60, 75, 90, 105, 120]

export const formInitValues = {
    calendarLoading: false,
    guestCompany: null,
    hostCompany: null,
    durations: durations.map(item => ({ value: item, label: item.toString() })),
    hostIds: [],
    guestIds: [],
    guests: [],
    hallId: null,
    tableId: null,
    scheduledMeetings: [],
    marks: durations.reduce((acc, item) => ({ ...acc, [item]: item.toString() + 'min' }), {}),
    selectedTimes: [],
    fixedLocation: true
}

export enum MeetingStatus {
    PENDING = 'Pending',
    SCHEDULED = 'Scheduled',
    RESCHEDULED = 'Rescheduled',
    CONDUCTED = 'Conducted',
    CANCELED = 'Canceled',
    MISSED = 'Missed'
}

export const meetingStatusColors = {
    Pending: '#FFC22D',
    Scheduled: '#FFC22D',
    Rescheduled: '#FFC22D',
    Conducted: '#22B254',
    Canceled: '#F14D4D',
    Missed: '#F14D4D'
}

export enum MeetingType {
    IN_PERSON = 'In-person',
    ZOOM = 'Zoom',
    TEAMS = 'Teams',
    ALADDIN_MEETING = 'Aladdin Meeting'
}
