import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
import { initialState } from '@utils/constants/requestConstants'

export interface IEditPayload extends ISagaCb {
    id: string
    data: {
        status?: string
        type?: string
        creditLimit?: number
        features?: string[]
        countries?: string[]
        locations?: string[]
    }
    avatar?: any
}

const editSlice = createSlice({
    name: 'subscription/company-subscription-edit',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IEditPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const editActions = editSlice.actions
export default editSlice.reducer
