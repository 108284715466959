import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { LOCALSTORAGE_KEYS } from '@features/General'
import { applyPermissions } from '@utils/helpers/applyPermissionsHelper'

interface TaskAction extends Action {
    payload: {
        eventId: string
        sagaCB?: {
            onSuccess: any
        }
    }
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.contactProfile
function* contactProfileSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/companies/contacts/profile`, {
            headers: {
                'event-id': payload.eventId
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            yield localStorage.setItem(LOCALSTORAGE_KEYS.CONTACT_INFO, JSON.stringify(response.result))
            applyPermissions(response.result?.role)
            yield payload?.sagaCB?.onSuccess(response.result)
        } else {
            if (typeof payload?.sagaCB?.onError === 'function') {
                payload?.sagaCB?.onError(response)
            }
        }
    } catch (error) {
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload?.sagaCB?.onError(error?.response?.data)
        }
        yield put(onFailure(error))
    }
}

function* contactProfileSaga(): any {
    yield takeLatest<TaskAction>(onRequest, contactProfileSagaTask)
}

export default contactProfileSaga
