import { createSlice } from '@reduxjs/toolkit'
import uniq from 'lodash/uniq'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
import { toggleValueInArray } from '@features/General/handlers'

const oneSideSelectDetailsSlice = createSlice({
    name: 'ui/one-side-select',
    initialState: {
        ...listUIInitialState,
        expandedRowKeys: []
    },
    reducers: {
        ...listUIReducers,
        toggleExpandedRowKey(state, action) {
            state.expandedRowKeys = toggleValueInArray(state.expandedRowKeys, action.payload)
        },
        addExpandedRowKey(state, action) {
            state.expandedRowKeys = uniq([...state.expandedRowKeys, action.payload])
        },
        resetExpandedRowKeys(state) {
            state.expandedRowKeys = []
        }
    }
})

export const oneSideSelectDetailsActions = oneSideSelectDetailsSlice.actions
export default oneSideSelectDetailsSlice.reducer
