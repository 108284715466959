import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.companies.search

function* searchSagaTask({ payload }: ListTaskAction) {
    try {
        const params = yield getParams(payload, true)

        const response = yield axios.get(`/search/companies`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            yield payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* searchSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, searchSagaTask)
}

export default searchSaga
