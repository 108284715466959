import {
    CognitoUser,
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js'
import notification from 'antd/lib/notification'
import isEmpty from 'lodash/isEmpty'
import { history } from '@components/App'
import routes from '@utils/constants/routes'
import { COOKIE_KEYS, getCookie } from '@features/General'
import UserPool from './UserPool'
import store from '@app-store/store'
import { rootActions } from '@app-store/slices'

type LoginOptionsType = {
    username: string
    password?: string
    newPassword?: string
    code?: string
    isActivation?: boolean
}

type NewPasswordOptionsType = {
    password: string
    cognitoUser: any
}

export function signupCognitoUser(values) {
    const attributeList = []
    const {
        email = '',
        password = '',
        firstname = '',
        lastname = '',
        company = '',
        country = '',
        city = '',
        mobile = ''
    } = values
    if (firstname) {
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'given_name',
                Value: firstname
            })
        )
    }
    if (lastname) {
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: lastname
            })
        )
    }
    if (company) {
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'custom:company',
                Value: company
            })
        )
    }
    if (country) {
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'custom:country',
                Value: country
            })
        )
    }
    if (city) {
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'custom:city',
                Value: city
            })
        )
    }
    if (mobile) {
        attributeList.push(
            new CognitoUserAttribute({
                Name: 'custom:mobile',
                Value: mobile
            })
        )
    }
    return new Promise((resolve, reject) =>
        UserPool.signUp(email, password, attributeList, null, (err, result) => {
            if (err) {
                reject(err)
            } else {
                store.dispatch(
                    rootActions.ui.authSlider.setLoggedInInfo({
                        email,
                        password
                    })
                )
                resolve(result)
            }
        })
    )
}

export function forgotpasswordCognitoUser(values) {
    const userData = {
        Username: values.email,
        Pool: UserPool
    }

    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) =>
        cognitoUser.forgotPassword({
            onSuccess: data => {
                resolve(data)
            },
            onFailure: error => {
                reject(error)
            }
        })
    )
}

export function confirmPasswordCognitoUser(values) {
    const userData = {
        Username: values.email,
        Pool: UserPool
    }

    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) =>
        cognitoUser.confirmPassword(values.code, values.password, {
            onSuccess: () => {
                resolve('')
            },
            onFailure: error => {
                reject(error.message)
            }
        })
    )
}

export function loginCognitoUser(values: LoginOptionsType) {
    const { username = '', password = '', isActivation = false } = values

    const authenticationData = {
        Username: username,
        Password: password
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    const userData = {
        Username: username,
        Pool: UserPool
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                resolve(result)
            },
            onFailure: function (err) {
                if (err.message.includes('User is not confirmed')) {
                    history.push(routes.login.confirmation._)
                    store.dispatch(
                        rootActions.ui.authSlider.setLoggedInInfo({
                            email: username,
                            cognitoUser,
                            isActivation,
                            password,
                            loggingIn: false
                        })
                    )
                }
                reject(err)
            },
            newPasswordRequired: function (userAttributes) {
                store.dispatch(
                    rootActions.ui.authSlider.setLoggedInInfo({
                        email: username,
                        cognitoUser,
                        isActivation,
                        loggingIn: false
                    })
                )
                history.push(routes.login.new_password._)
            }
        })
    })
}

export function confirmRegistrationCognitoUser(values) {
    const { username, code } = values
    const userData = {
        Username: username,
        Pool: UserPool
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, function (err, result) {
            if (err) {
                notification.error({
                    message: 'The activation code is wrong.',
                    placement: 'topRight',
                    duration: 3
                })
                reject(err)
            } else {
                notification.success({
                    message: 'Your account has been activated successfully.',
                    placement: 'topRight',
                    duration: 3
                })
                resolve(result)
            }
        })
    })
}

export function resendConfirmationCodeCognitoUser(values) {
    const { username } = values
    const userData = {
        Username: username,
        Pool: UserPool
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                notification.error({
                    message: 'There is a problem in sending activation code, please try again later.',
                    placement: 'topRight',
                    duration: 3
                })
                reject(err)
            } else {
                notification.success({
                    message: 'New activation code has been sent successfully.',
                    placement: 'topRight',
                    duration: 3
                })
                store.dispatch(
                    rootActions.ui.authSlider.setLoggedInInfo({
                        email: username,
                        cognitoUser
                    })
                )
                resolve(result)
            }
        })
    })
}

export function sendOtpCodeCognitoUser(values) {
    const authenticationData = {
        Username: values.email
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    const userData = {
        Username: values.email,
        Pool: UserPool
    }

    const cognitoUser = new CognitoUser(userData)
    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH')

    return new Promise((resolve, reject) =>
        cognitoUser.initiateAuth(authenticationDetails, {
            onSuccess: function (result) {
                resolve(result)
            },
            onFailure: function (err) {
                reject(err)
            },
            customChallenge: function (challengeParameters) {
                resolve(challengeParameters)
                store.dispatch(
                    rootActions.ui.otpLogin.setLoggedInInfo({
                        email: values.email,
                        cognitoUser
                    })
                )
            }
        })
    )
}

export function loginWithOtpCognitoUser(values) {
    const { email = '', code = '', cognitoUser } = values
    return new Promise((resolve, reject) => {
        cognitoUser.sendCustomChallengeAnswer(code, {
            onSuccess: function (result) {
                resolve(result)
            },
            onFailure: function (err, data) {
                notification.error({ message: 'The code is not correct', duration: 3 })
                store.dispatch(rootActions.ui.authSlider.setLoggedInInfo({ loggingIn: false }))
                reject(err)
            },
            newPasswordRequired: function (userAttributes) {
                store.dispatch(
                    rootActions.ui.authSlider.setLoggedInInfo({
                        email,
                        cognitoUser
                    })
                )
                history.push(routes.login.new_password._)
            }
        })
    })
}

export function newPasswordCognitoUser(values: NewPasswordOptionsType) {
    const { password = '', cognitoUser } = values
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(
            password,
            {},
            {
                onSuccess: function (result) {
                    notification.success({
                        message: 'Your password has been changed successfully.',
                        placement: 'topRight',
                        duration: 3
                    })
                    resolve(result)
                },
                onFailure: function (err) {
                    reject(err)
                }
            }
        )
    })
}

export function checkUserSession() {
    const cognitoUser = UserPool.getCurrentUser()
    if (cognitoUser !== null) {
        cognitoUser.getSession(function (err, session) {
            if (err) {
                return
            }
            const RefreshToken = getCookie(COOKIE_KEYS.ALA_TOKEN)
            if (!isEmpty(RefreshToken)) {
                const token = new CognitoRefreshToken({ RefreshToken })
                cognitoUser.refreshSession(token, (err, session) => undefined)
            }
        })
    }
}
