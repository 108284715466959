import { createSlice } from '@reduxjs/toolkit'

export interface ITemplatesDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    pureFilters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    addPresignedURL?: boolean
    populates?: any
}

const initialState: ITemplatesDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    pureFilters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: true,
    addPresignedURL: true,
    populates: []
}

const templatesSlice = createSlice({
    name: 'ui/template',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        addPureFilter(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state.pureFilters[key] = action.payload[key]
            }
        },
        onResetFilters(state) {
            state.filters = {}
            state.pureFilters = {}
        },
        onChangeData(state, action) {
            Object.keys(action.payload).map(key => {
                state[key] = action.payload[key]
            })
        }
    }
})

export const templatesActions = templatesSlice.actions
export default templatesSlice.reducer
