import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IGenerateWishMatchPayload extends ISagaCb {
    data: {
        matchListIds: string[]
        createMatchList: boolean
        companyIds?: string
    }
}

export interface IState {
    status: string
    data: any
    errors: any
}

const initialState: IState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const generateWishMatch = createSlice({
    name: 'hub/matchLists/generate-wish-match',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<IGenerateWishMatchPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.data = { count: 0, data: [] }
            state.errors = action.payload
        }
    }
})

export const generateWishMatchActions = generateWishMatch.actions
export default generateWishMatch.reducer
