import { createSlice } from '@reduxjs/toolkit'

export interface ICommonState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    pureFilters?: any
    sort?: string
    featureModal: {
        isOpen?: boolean
        editData?: any
    }
}

const initialState: ICommonState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    pureFilters: {},
    sort: '-createdAt',
    featureModal: {
        isOpen: false,
        editData: null
    }
}

const contactCalendarSlice = createSlice({
    name: 'ui/contact-calendar',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        addPureFilter(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state.pureFilters[key] = action.payload[key]
            }
        },
        onResetFilters(state) {
            state.filters = {}
            state.pureFilters = {}
        },
        setFeatureModalInfo(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.featureModal[item] = action.payload[item]
            }
        },
        onChangeData(state, action) {
            Object.keys(action.payload).map(key => {
                state[key] = action.payload[key]
            })
        }
    }
})

export const contactCalendarActions = contactCalendarSlice.actions
export default contactCalendarSlice.reducer
