import { combineReducers } from 'redux'
import listReducer, { listNotificationActions } from '@app-store/slices/notifications/list'
import listSearchReducer, { listSearchNotificationActions } from '@app-store/slices/notifications/listSearch'
import markAsReadReducer, { markAsReadActions } from '@app-store/slices/notifications/markAsRead'
import staticEnumsReducer, { staticEnumsActions } from '@app-store/slices/notifications/stacticEnums'
import sendNotificationReducer, { sendNotificationActions } from '@app-store/slices/notifications/sendNotification'
import verificationInitiateReducer, {
    verificationInitiateActions
} from '@app-store/slices/notifications/verificationInitiate'
import verificationValidateReducer, {
    verificationValidateActions
} from '@app-store/slices/notifications/verificationValidate'
import getUnreadNumbersReducer, { getUnreadNumbersActions } from '@app-store/slices/notifications/getUnreadNumbers'
export const notificationReducer = combineReducers({
    list: listReducer,
    listSearch: listSearchReducer,
    markAsRead: markAsReadReducer,
    staticEnums: staticEnumsReducer,
    sendNotification: sendNotificationReducer,
    verificationInitiate: verificationInitiateReducer,
    verificationValidate: verificationValidateReducer,
    getUnreadNumbers: getUnreadNumbersReducer
})

export const notificationActions = {
    list: listNotificationActions,
    listSearch: listSearchNotificationActions,
    markAsRead: markAsReadActions,
    staticEnums: staticEnumsActions,
    sendNotification: sendNotificationActions,
    verificationInitiate: verificationInitiateActions,
    verificationValidate: verificationValidateActions,
    getUnreadNumbers: getUnreadNumbersActions
}
