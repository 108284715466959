export default {
    _: '/',
    name: '',
    invalid_sub_domain: {
        _: '/invalid-sub-domain',
        name: 'invalid sub domain'
    },
    login: {
        _: '/login',
        name: 'login',
        otp: {
            _: '/otp-login',
            name: 'otp login',
            code: {
                _: '/otp-login/code',
                name: 'Otp new code'
            }
        },
        confirmation: {
            _: '/confirmation',
            name: 'confirmation'
        },
        new_password: {
            _: '/new-password',
            name: 'Create new password'
        }
    },
    sign_up: {
        _: '/sign-up',
        name: 'signup',
        email_confirm: {
            _: '/sign-up/email-confirm',
            name: 'Check Email '
        }
    },
    activation_sign_up: {
        _: '/activation-sign-up',
        name: 'activation signup'
    },
    support: {
        _: '/support',
        name: 'Support'
    },
    join_aladdinb2b: {
        _: '/join-aladdinb2b',
        name: 'signup'
    },
    forgot_password: {
        _: '/forgot-password',
        name: 'forgot password',
        new: {
            _: '/forgot-password/new',
            name: 'new password'
        },
        success: {
            _: '/forgot-password/success',
            name: 'success password'
        }
    },
    activation: {
        _: '/activation',
        name: 'Activation'
    },
    itp: {
        _: '/itp',
        name: 'ITP',
        itp_profile: {
            _: '/itp/profile',
            name: 'ITP Profile'
        }
    },
    crm: {
        _: '/crm',
        name: 'crm',
        confirmation: {
            _: '/crm/confirmation'
        },
        complete: {
            _: '/crm/complete'
        }
    },
    integrations: {
        _: '/integrations',
        service: {
            _: '/integrations/:service'
        }
    },
    open_events: {
        _: '/open-events',
        name: 'open events'
    },
    invitation: {
        _: '/invitation',
        name: 'invitation',
        sign_up: {
            _: '/invitation/sign-up',
            name: 'signup'
        },
        login: {
            _: '/invitation/login',
            name: 'login'
        },
        done: {
            _: '/invitation/done',
            name: 'done'
        }
    },
    database_import: {
        _: '/database-import',
        name: 'Database Import',
        file: {
            _: '/database-import/file',
            name: 'File',
            objects: {
                _: '/database-import/file/objects',
                name: 'Objects'
            },
            files: {
                _: '/database-import/file/files',
                name: 'Files'
            }
        },
        entity: {
            _: '/database-import/entity',
            name: 'Entity'
        },
        upload: {
            _: '/database-import/upload',
            name: 'Upload'
        },
        map_columns: {
            _: '/database-import/map-columns',
            name: 'Map Columns'
        },
        complete: {
            _: '/database-import/complete',
            name: 'Compelete'
        }
    },
    itp_import: {
        _: '/itp-import',
        name: 'Itp Import',
        upload: {
            _: '/itp-import/upload',
            name: 'Upload'
        },
        map_columns: {
            _: '/itp-import/map-columns',
            name: 'Map Columns'
        },
        complete: {
            _: '/itp-import/complete',
            name: 'Compelete'
        }
    },
    import_demo_data: {
        _: '/import-demo-data',
        name: 'Import Demo Data',
        templates: {
            _: '/import-demo-data/templates',
            name: 'Templates'
        },
        types: {
            _: '/import-demo-data/types',
            name: 'Types'
        },
        result: {
            _: '/import-demo-data/result',
            name: 'Result'
        }
    },
    organizer: {
        _: '/organizer',
        name: 'organizer',
        events: {
            _: '/organizer/events',
            name: 'events',
            details: {
                _: '/organizer/events/details',
                name: 'details'
            },
            email_campaign: {
                _: '/organizer/events/email-campaign',
                name: 'email campaign'
            },
            branding: {
                _: '/organizer/events/branding',
                name: 'branding'
            },
            import: {
                _: '/organizer/events/import',
                name: 'import'
            },
            category: {
                _: '/organizer/events/category',
                name: 'category'
            },
            successful: {
                _: '/organizer/events/successful',
                name: 'successful'
            }
        }
    },
    exhibitor: {
        _: '/exhibitor',
        name: 'exhibitor',
        onboarding: {
            _: '/exhibitor/onboarding',
            name: 'onboarding',
            events: {
                _: '/exhibitor/onboarding/events',
                name: 'events'
            },
            itp: {
                _: '/exhibitor/onboarding/ITP/:state',
                name: 'ITP'
            },
            categories: {
                _: '/exhibitor/onboarding/categories',
                name: 'categories'
            },
            calendar: {
                _: '/exhibitor/onboarding/calendar/:state',
                name: 'calendar'
            },
            profile: {
                _: '/exhibitor/onboarding/profile',
                name: 'profile'
            },
            location: {
                _: '/exhibitor/onboarding/location',
                name: 'location'
            },
            survey: {
                _: '/exhibitor/onboarding/survey',
                name: 'survey'
            },
            crm: {
                _: '/exhibitor/onboarding/crm/:state',
                name: 'crm'
            }
        }
    },
    sync_calendar: {
        _: '/sync-calendar/:token',
        name: '',
        successful_calendar: {
            _: '/successful-calendar',
            name: ''
        }
    },
    matching: {
        _: '/matching',
        name: 'Matching',
        match_engine: {
            _: '/matching/match_engine',
            name: 'Match engine'
        },
        all_sharedlist: {
            _: '/matching/all_sharedlist',
            name: 'All Sharedlist'
        },
        match_list: {
            _: '/matching/match_list',
            name: 'Match list',
            details: {
                _: '/matching/match_list/details/:id',
                name: 'Match list details',
                view: {
                    _: '/matching/match_list/details/:id/view',
                    name: 'Match list view'
                },
                responses: {
                    _: '/matching/match_list/details/:id/responses',
                    name: 'Match list responses'
                },
                meetings: {
                    _: '/matching/match_list/details/:id/meetings',
                    name: 'Match list meetings'
                }
            },
            response: {
                _: '/matching/match_list/response/:matchListId/:id',
                name: 'Shared list responses'
            }
        },
        match_list_select: {
            _: '/matching/match_list/select/:token',
            name: 'Select Match list',
            sync_calendar: {
                _: '/matching/sync_calendar/:token',
                name: '',
                connect_account: {
                    _: '/matching/sync_calendar/connect/:state/:token',
                    name: ''
                }
            },
            availability_calendar: {
                _: '/matching/availability_calendar/:token',
                name: ''
            },
            successful_calendar: {
                _: '/matching/successful_calendar',
                name: ''
            }
        },
        matches: {
            _: '/matching/matches',
            name: 'Matches',
            suggested_match_list: {
                _: '/matching/matches/suggested-match-list',
                name: 'Suggested match list'
            },
            meeting_wish_list: {
                _: '/matching/matches/meeting-wish-list',
                name: 'Meeting wish list',
                response: '/matching/matches/meeting-wish-list/response/:id'
            },
            perfect_match: {
                _: '/matching/matches/perfect-match',
                name: 'Perfect match'
            },
            my_scan: {
                _: '/matching/matches/my-scan',
                name: 'My scan'
            }
        },
        perfect_match: {
            _: '/matching/perfect-match',
            name: 'Perfect Match'
        },
        one_side_select: {
            _: '/matching/1-side-select',
            name: '1 Side Select'
        }
    },
    operation: {
        _: '/operation',
        name: 'Operation',
        match_engine: {
            _: '/operation/match-engine',
            name: 'match engine'
        },
        match_list: {
            _: '/operation/match_list',
            view: '/operation/match_list/:match_list_id/:page',
            response: '/operation/match_list/response/:match_list_id/:company_id',
            name: 'Match list'
        },
        event_planner: {
            _: '/operation/event_planner',
            name: 'event planner'
        },
        meeting_request: {
            _: '/operation/meeting_request',
            name: 'meeting request'
        }
    },
    settings: {
        _: '/settings',
        name: 'Settings',
        event: {
            _: '/settings/event',
            name: 'Event',
            event_info: {
                _: '/settings/event/info',
                name: 'Event info'
            },
            branding: {
                _: '/settings/event/branding',
                name: 'Branding'
            },
            import: {
                _: '/settings/event/import',
                name: 'Import Attendees'
            },
            import_itp: {
                _: '/settings/event/itp-import',
                name: 'Import Itp'
            },
            categories: {
                _: '/settings/event/categories',
                name: 'Categories'
            },
            visibility: {
                _: '/settings/event/visibility',
                name: 'Visibility',
                profile: {
                    _: '/settings/event/visibility/:settingId/:name/:id',
                    name: ':name'
                }
            },
            match: {
                _: '/settings/event/match',
                name: 'Match'
            },
            meeting: {
                _: '/settings/event/meeting',
                name: 'Meetings'
            },
            notifications: {
                _: '/settings/event/notifications',
                name: 'Notifications'
            },
            keywords: {
                _: '/settings/event/keywords',
                name: 'Keywords'
            },
            global_renaming: {
                _: '/settings/event/global-renaming',
                name: 'Global Renaming'
            },
            tables: {
                _: '/settings/event/tables',
                name: 'Tables',
                profile: {
                    _: '/settings/event/tables/:hallId',
                    name: ''
                }
            },
            badges: {
                _: '/settings/event/badges',
                name: 'Badges'
            },
            company_badges: {
                _: '/settings/event/companyBadges',
                name: 'CompanyBadges',
                preview: {
                    _: '/settings/event/companyBadges/preview',
                    name: 'CompanyBadgesPreview'
                }
            },
            event_profile: {
                _: '/settings/event/event-profile',
                name: 'EventProfile'
            }
        },
        event_agenda: {
            _: '/settings/event_agenda',
            name: 'event planner'
        },
        account: {
            _: '/settings/account',
            name: 'Account',
            linkedin_account: {
                _: '/settings/account/linkedin-account',
                name: 'LinkedIn Account'
            }
        },
        team: {
            _: '/settings/team',
            name: 'Team'
        },
        order_history: {
            _: '/settings/order-history',
            name: 'Order History'
        },
        subscriptions: {
            _: '/settings/subscriptions',
            name: 'Subscriptions',
            subscription_plans: {
                _: '/settings/subscriptions/plans',
                name: 'Subscription Plans'
            },
            event_subscription: {
                _: '/settings/subscriptions/event-subscription',
                name: 'Event Subscription'
            },
            orders: {
                _: '/settings/subscriptions/orders',
                name: 'orders',
                details: {
                    _: '/settings/subscriptions/orders/:id',
                    name: 'order detail'
                },
                payment: {
                    _: '/settings/subscriptions/orders/payment/:id',
                    name: 'payment'
                }
            },
            transactions: {
                _: '/settings/subscriptions/transactions',
                name: 'transactions'
            },
            my_profile: {
                _: '/settings/subscriptions/my-profile',
                name: 'My Profile'
            },
            payment: {
                _: '/settings/subscriptions/payment',
                name: 'Payment'
            },
            subscription: {
                _: '/settings/subscriptions/subscription',
                name: 'Subscription'
            },
            invoices: {
                _: '/settings/subscriptions/invoices',
                name: 'Invoices'
            },
            user_permissions: {
                _: '/settings/subscriptions/user-permissions',
                name: 'User Permissions'
            },
            geographic_filters: {
                _: '/settings/subscriptions/geographic-filters',
                name: 'Feographic Filters'
            },
            feature: {
                _: '/settings/subscriptions/feature',
                name: 'Feature'
            },
            manage_subscription: {
                _: '/settings/subscriptions/manage-subscription',
                name: 'Manage Subscription'
            }
        },
        users: {
            _: '/settings/users',
            name: 'User Management'
        },
        crm_integration: {
            _: '/settings/crm-integration',
            name: 'CRM Integration'
        },
        groups: {
            _: '/settings/groups',
            name: 'groups'
        },
        communications: {
            _: '/settings/communications',
            name: 'Communications',
            menu_visibility: {
                _: '/settings/communications/menu-visibility',
                name: 'Menu visibility'
            },
            templates: {
                _: '/settings/communications/templates',
                name: 'Templates'
            },
            tour: {
                _: '/settings/communications/tour',
                name: 'System tour'
            },
            survey: {
                _: '/settings/communications/survey',
                name: 'Survey',
                create: {
                    _: '/settings/communications/survey/create',
                    name: 'Create',
                    info: {
                        _: '/settings/communications/survey/create/info',
                        name: 'Survey'
                    },
                    questions: {
                        _: '/settings/communications/survey/create/questions/:id',
                        name: 'Questions'
                    },
                    responses: {
                        _: '/settings/communications/survey/create/responses/:id',
                        name: 'Responses'
                    },
                    actions: {
                        _: '/settings/communications/survey/create/actions/:id',
                        name: 'Actions'
                    }
                },
                edit: {
                    _: '/settings/communications/survey/edit',
                    name: 'Edit',
                    info: {
                        _: '/settings/communications/survey/edit/:id',
                        name: 'Survey'
                    }
                }
            },
            setup_sender: {
                _: '/settings/communications/setup-sender',
                name: 'Setup Sender'
            },
            campaign: {
                _: '/settings/communications/campaign',
                name: 'Campaign',
                edit: {
                    _: '/settings/communications/campaign/:id',
                    name: 'Edit Campaign'
                }
            },
            to_do: {
                _: '/settings/communications/to-do',
                name: 'To-Dos'
            },
            statistics: {
                _: '/settings/communications/statistics',
                name: 'Statistics'
            },
            settings: {
                _: '/settings/communications/settings',
                name: 'Settings'
            },
            send_notifications: {
                _: '/settings/communications/send-notifications',
                name: 'Send Notifications',
                survey_feedback_by_token: {
                    _: '/survey',
                    name: 'Survey Feedback by token'
                }
            },
            welcome_email: {
                _: '/settings/communications/campaign/welcome-email/:id',
                name: 'Welcome Email',
                edit: {
                    _: '/settings/communications/campaign/welcome-email/:id/:idEmail',
                    name: 'Welcome Email'
                }
            },
            whats_new: {
                _: '/settings/communications/whats-new',
                name: 'Whats New',
                create: {
                    _: '/settings/communications/whats-new/create',
                    name: 'Create Whats New'
                },
                edit: {
                    _: '/settings/communications/whats-new/edit/:id',
                    name: 'Edit Whats New'
                },
                detail: {
                    _: '/settings/communications/whats-new/detail',
                    name: 'Detail Whats New'
                }
            }
        }
    },
    meetings: {
        _: '/meetings',
        name: 'Meetings',
        outgoing: {
            _: '/meetings/outgoing',
            name: 'Outgoing requests'
        },
        incoming: {
            _: '/meetings/incoming',
            name: 'Incoming requests'
        },
        meeting_request: {
            _: '/meetings/all-meeting-requests',
            name: 'Meeting Request'
        },
        meeting_agenda: {
            _: '/meetings/meeting-agenda',
            name: 'Meeting Agenda',
            profile: {
                _: '/meetings/meeting-agenda/:id',
                name: 'Meeting'
            },
            create: {
                _: '/meetings/meeting-agenda/create',
                setCompanies: '/meetings/meeting-agenda/create/:hostCompanyId/:guestCompanyId/:hostId/:matchListId',
                setContacts:
                    '/meetings/meeting-agenda/create/init-contacts/:hostCompanyId/:guestCompanyId/:hostId/:guestId/:matchListId',
                name: 'Create'
            },
            add: {
                _: '/meetings/meeting-agenda/add',
                setCompanies: '/meetings/meeting-agenda/add/:hostCompanyId/:guestCompanyId/:hostId',
                name: 'Add'
            },
            edit: {
                _: '/meetings/meeting-agenda/edit/:id',
                name: 'Edit'
            }
        },
        my_agenda: {
            _: '/meetings/my-agenda',
            name: 'My Agenda',
            profile: {
                _: '/meetings/my-agenda/:id',
                name: 'Meeting'
            }
        },
        event_agenda: {
            _: '/meetings/event-agenda',
            name: 'Event Agenda'
        },
        calendar: {
            _: '/meetings/calendar',
            name: 'Calendar',
            clone: {
                _: '/meetings/calendar/clone/:id',
                name: 'Clone',
                meeting_scheduler: {
                    _: '/meetings/calendar/clone/:id/meeting-scheduler',
                    name: 'Availability Calendar'
                },
                availability_visualizer: {
                    _: '/meetings/calendar/clone/:id/availability-visualizer',
                    name: 'Calendar'
                }
            },
            create: {
                _: '/meetings/calendar/create',
                name: 'Create',
                meeting_scheduler: {
                    _: '/meetings/calendar/create/meeting-scheduler',
                    name: 'Availability Calendar'
                },
                availability_visualizer: {
                    _: '/meetings/calendar/create/availability-visualizer',
                    name: 'Calendar'
                }
            },
            edit: {
                _: '/meetings/calendar/edit/:id',
                name: 'Create',
                meeting_scheduler: {
                    _: '/meetings/calendar/edit/:id/meeting-scheduler',
                    name: 'Availability Calendar'
                },
                availability_visualizer: {
                    _: '/meetings/calendar/edit/:id/availability-visualizer',
                    name: 'Calendar'
                }
            }
        },
        create_meeting: {
            _: '/meetings/create-meeting',
            name: 'Create Meeting',
            create: {
                _: '/meetings/create-meeting/:link',
                name: 'Create Meeting'
            }
        },
        meetingInfo: {
            _: '/meetings/meeting-info',
            name: 'MeetingInfo'
        },
        wish_campaign: {
            _: '/meetings/wish_campaign',
            name: 'Wish Campaign',
            edit: {
                _: '/meetings/wish_campaign/:id',
                setCompany: '/meetings/wish_campaign/:id/:companyId',
                setContact: '/meetings/wish_campaign/:id/:companyId/:contactId',
                name: 'Edit Wish Campaign'
            },
            meeting_request: {
                _: '/meetings/wish_campaign/meeting-request',
                name: 'Meeting request',
                calendar: {
                    _: '/meetings/wish_campaign/meeting-request/calendar',
                    name: 'Details'
                },
                accept: {
                    _: '/meetings/wish_campaign/meeting-request/accept',
                    name: 'Accept Request'
                },
                not_interested: {
                    _: '/meetings/wish_campaign/meeting-request/not-interested',
                    name: 'Not Interested'
                }
            }
        }
    },
    pipeline: {
        _: '/pipeline',
        pipeline: {
            _: '/pipeline/pipeline',
            name: 'Pipeline'
        },
        trade_hub: {
            _: '/pipeline/trade-hub',
            name: 'rfq',
            rfq_market: {
                _: '/pipeline/trade-hub/rfq-market',
                name: 'RFQ Market',
                detail: {
                    _: '/pipeline/trade-hub/rfq-market/detail/:id',
                    name: 'RFQ Market detail'
                },
                create: {
                    _: '/pipeline/trade-hub/rfq-market/create',
                    name: 'create',
                    detail: {
                        _: '/pipeline/trade-hub/rfq-market/create/detail',
                        name: 'detail'
                    },
                    product: {
                        _: '/pipeline/trade-hub/rfq-market/create/product',
                        name: 'product',
                        addNewProduct: {
                            _: '/pipeline/trade-hub/rfq-market/create/product/new',
                            name: 'new-product'
                        },
                        newProductDetail: {
                            _: '/pipeline/trade-hub/rfq-market/create/product/add-new-product',
                            name: 'new-product-detail'
                        }
                    },
                    review: {
                        _: '/pipeline/trade-hub/rfq-market/create/review',
                        name: 'review',
                        inviteSuppliers: {
                            _: '/pipeline/trade-hub/rfq-market/create/review/invite-suppliers',
                            name: 'invite-suppliers'
                        }
                    },
                    service: {
                        _: '/pipeline/trade-hub/rfq-market/create/service',
                        name: 'service',
                        addNewProduct: {
                            _: '/pipeline/trade-hub/rfq-market/create/service/new',
                            name: 'add-new-service'
                        },
                        newProductDetail: {
                            _: '/pipeline/trade-hub/rfq-market/create/service/new-service-detail',
                            name: 'add-new-detail-service'
                        }
                    }
                }
            },
            my_rfq: {
                _: '/pipeline/trade-hub/my-rfq',
                name: 'rfq',
                incoming: {
                    _: '/pipeline/trade-hub/my-rfq/incoming',
                    name: 'incoming',
                    detail: {
                        _: '/pipeline/trade-hub/my-rfq/incoming/detail/:id',
                        name: 'detail'
                    }
                },
                outgoing: {
                    _: '/pipeline/trade-hub/my-rfq/outgoing',
                    name: 'outgoing',
                    detail: {
                        _: '/pipeline/trade-hub/my-rfq/outgoing/detail/:id',
                        name: 'detail',
                        info: {
                            _: '/pipeline/trade-hub/my-rfq/outgoing/detail/:id/info',
                            name: 'info'
                        },
                        suppliers: {
                            _: '/pipeline/trade-hub/my-rfq/outgoing/detail/:id/suppliers',
                            name: 'Suppliers'
                        },
                        proposals: {
                            _: '/pipeline/trade-hub/my-rfq/outgoing/detail/:id/proposals',
                            name: 'Proposals'
                        },
                        purchasing_order: {
                            _: '/pipeline/trade-hub/my-rfq/outgoing/detail/:id/purchasing-order',
                            name: 'Purchasing Order'
                        }
                    }
                }
            },
            quotations: {
                _: '/pipeline/trade-hub/quotes',
                name: 'quotes',
                incoming: {
                    _: '/pipeline/trade-hub/quotes/incoming',
                    name: 'incoming'
                },
                outgoing: {
                    _: '/pipeline/trade-hub/quotes/outgoing',
                    name: 'outgoing'
                },
                create: {
                    _: '/pipeline/trade-hub/quotes/create',
                    name: 'create-quote',
                    addCompany: {
                        _: '/pipeline/trade-hub/quotes/create/add-company',
                        name: 'add-company'
                    },
                    details: {
                        _: '/pipeline/trade-hub/quotes/create/details',
                        name: 'create-quote-detail'
                    },
                    addProduct: {
                        _: '/pipeline/trade-hub/quotes/create/add-product',
                        name: 'product-or-service'
                    },
                    preview: {
                        _: '/pipeline/trade-hub/quotes/create/preview',
                        name: 'preview'
                    }
                },
                send: {
                    _: '/pipeline/trade-hub/quotes/send/:id',
                    detail: {
                        _: '/pipeline/trade-hub/quotes/send/:id/detail',
                        name: 'detail'
                    },
                    name: 'send-qutote',
                    pricing: {
                        _: '/pipeline/trade-hub/quotes/send/:id/pricing',
                        name: 'pricing'
                    },
                    preview: {
                        _: '/pipeline/trade-hub/quotes/send/:id/preview',
                        name: 'preview'
                    }
                }
            }
        },
        messages: {
            _: '/pipeline/messages',
            name: 'messages'
        }
    },
    hub: {
        _: '/hub',
        name: 'Hub',
        meetings: {
            _: '/hub/meetings',
            name: 'Meetings',
            outgoing: {
                _: '/hub/meetings/outgoing',
                name: 'Outgoing requests'
            },
            incoming: {
                _: '/hub/meetings/incoming',
                name: 'Incoming requests'
            },
            meeting_agenda: {
                _: '/hub/meetings/meeting-agenda',
                name: 'Meeting Agenda',
                profile: {
                    _: '/hub/meetings/meeting-agenda/:id',
                    name: 'Meeting'
                },
                create: {
                    _: '/hub/meetings/meeting-agenda/create',
                    name: 'Create'
                },
                edit: {
                    _: '/hub/meetings/meeting-agenda/edit/:id',
                    name: 'Edit'
                }
            },
            my_agenda: {
                _: '/hub/meetings/my-agenda',
                name: 'My Agenda',
                profile: {
                    _: '/hub/meetings/my-agenda/:id',
                    name: 'Meeting'
                }
            }
        }
    },
    meeting_request: {
        _: '/meeting-request',
        name: 'Meeting request',
        accept: {
            _: '/meeting-request/accept',
            name: 'Accept Request'
        },
        reject: {
            _: '/meeting-request/reject',
            name: 'Decline Request'
        },
        occupied: {
            _: '/meeting-request/occupied',
            name: 'Suggest Request'
        },
        suggest: {
            _: '/meeting-request/suggest',
            name: 'Suggest Request'
        },
        profile: {
            _: '/meeting-request/request',
            name: 'Accept Request'
        },
        meeing_slots: {
            _: '/meeting-request/meeing-slots',
            name: 'Meeting Slots'
        },
        login: {
            _: '/meeting-request/login',
            name: 'Request'
        },
        not_intrested: {
            _: '/meeting-request/not-intrested',
            name: 'Request'
        },
        request: {
            _: '/meeting-request/request',
            name: 'Request',
            participants: {
                _: '/meeting-request/request/participants',
                name: 'Participants'
            },
            calendar: {
                _: '/meeting-request/request/calendar',
                name: 'Details'
            },
            message: {
                _: '/meeting-request/request/message',
                name: 'Message'
            }
        }
    },
    dashboard: {
        _: '/dashboard',
        name: 'Dashbord',
        company_profile: {
            _: '/dashboard/company',
            name: 'company',
            profile: {
                _: '/dashboard/company/:id',
                name: ':company-name | Profile'
            },
            employees: {
                _: '/dashboard/company/:id/employees',
                name: ':company-name | Employees'
            },
            digital_shop: {
                _: '/dashboard/company/:id/digital-shop',
                name: ':company-name | Digital Shop'
            },
            services: {
                _: '/dashboard/company/:id/services',
                name: ':company-name | Services'
            },
            media: {
                _: '/dashboard/company/:id/media',
                name: ':company-name | Media'
            },
            documents: {
                _: '/dashboard/company/:id/documents',
                name: ':company-name | Documents'
            },
            itp: {
                _: '/dashboard/company/:id/itp',
                name: ':company-name | ITP',
                new: {
                    _: '/dashboard/company/:id/itp/new',
                    name: ':company-name | NEW',
                    location: {
                        _: '/dashboard/company/:id/itp/new/location',
                        name: ':company-name | LOCATION'
                    },
                    target_type: {
                        _: '/dashboard/company/:id/itp/new/target-type',
                        name: ':company-name | TARGET TYPE'
                    },
                    keywords: {
                        _: '/dashboard/company/:id/itp/new/keywords',
                        name: ':company-name | KEYWORDS'
                    }
                }
            },
            events: {
                _: '/dashboard/company/:id/events',
                name: ':company-name | Events'
            }
        },
        directory: {
            _: '/dashboard/directory',
            name: 'Directory',
            directory: {
                _: '/dashboard/directory/directory',
                name: 'Directory'
            },
            match: {
                _: '/dashboard/directory/match',
                name: 'Match',
                wish_list: {
                    _: '/dashboard/directory/match/wish-list',
                    name: 'Wish List'
                }
            }
        }
    },
    directory: {
        _: '/directory',
        oldDirectory: '/directory/old',
        name: 'Directory',
        unassigned: {
            _: '/directory/unassigned',
            companies: '/directory/unassigned/companies',
            all: '/directory/unassigned/all',
            contacts: '/directory/unassigned/contacts',
            name: 'Unassigned'
        },
        assigned: {
            _: '/directory/assigned',
            edit: '/directory/assigned/:id',
            companies: '/directory/assigned/:id/companies',
            all: '/directory/assigned/:id/all',
            contacts: '/directory/assigned/:id/contacts',
            name: 'Assigned'
        },
        published: {
            _: '/directory/published',
            companies: '/directory/published/companies',
            all: '/directory/published/all',
            contacts: '/directory/published/contacts',
            name: 'Unassigned'
        },
        internalPublished: {
            _: '/directory/internal',
            companies: '/directory/internal/companies',
            all: '/directory/internal/all',
            contacts: '/directory/internal/contacts',
            name: 'Unassigned'
        },
        contact_profile: {
            _: '/directory/contact/:id',
            name: 'profile',
            hall: {
                _: '/directory/contact/:id/hall',
                name: 'hall'
            },
            matches: {
                _: '/directory/contact/:id/matches',
                name: 'hall'
            },
            meetings: {
                _: '/directory/contact/:id/meetings',
                name: 'hall'
            },
            offers: {
                _: '/directory/contact/:id/offers',
                name: 'hall'
            },
            pipeline: {
                _: '/directory/contact/:id/pipeline',
                name: 'hall'
            },
            details: {
                _: '/directory/contact/:id/details',
                name: 'details'
            },
            history: {
                _: '/directory/contact/:id/history',
                name: 'history'
            },
            scans: {
                _: '/directory/contact/:id/scans',
                name: 'scans'
            },
            media: {
                _: '/directory/contact/:id/media',
                name: 'media'
            },
            documents: {
                _: '/directory/contact/:id/documents',
                name: 'documents'
            },
            advanced_edit: {
                _: '/directory/contact/:id/advanced-edit/:companyId',
                name: 'Advanced Edit'
            },
            calendar: {
                _: '/directory/contact/:id/calendar',
                name: 'calendar',
                clone: {
                    _: '/directory/contact/:id/calendar/clone/:calendarId',
                    name: 'Clone',
                    meeting_scheduler: {
                        _: '/directory/contact/:id/calendar/clone/:calendarId/meeting-scheduler',
                        name: 'Availability Calendar'
                    },
                    availability_visualizer: {
                        _: '/directory/contact/:id/calendar/clone/:calendarId/availability-visualizer',
                        name: 'Calendar'
                    }
                },
                create: {
                    _: '/directory/contact/:id/calendar/create',
                    name: 'Create',
                    meeting_scheduler: {
                        _: '/directory/contact/:id/calendar/create/meeting-scheduler',
                        name: 'Availability Calendar'
                    },
                    availability_visualizer: {
                        _: '/directory/contact/:id/calendar/create/availability-visualizer',
                        name: 'Calendar'
                    }
                },
                edit: {
                    _: '/directory/contact/:id/calendar/edit/:calendarId',
                    name: 'Create',
                    meeting_scheduler: {
                        _: '/directory/contact/:id/calendar/edit/:calendarId/meeting-scheduler',
                        name: 'Availability Calendar'
                    },
                    availability_visualizer: {
                        _: '/directory/contact/:id/calendar/edit/:calendarId/availability-visualizer',
                        name: 'Calendar'
                    }
                }
            },
            wish_list: {
                _: '/directory/contact/:id/wish-list',
                name: 'Wish List'
            }
        },
        company_profile: {
            _: '/directory/company/:id',
            name: 'Company',
            profile: {
                _: '/directory/company/:id/details',
                name: ':company-name | Profile'
            },
            meetings: {
                _: '/directory/company/:id/meetings',
                name: ':company-name | Meetings'
            },
            employees: {
                _: '/directory/company/:id/employees',
                name: ':company-name | Employees'
            },
            wish_list: {
                _: '/directory/company/:id/wish-list',
                name: ':company-name | Wish List'
            },
            digital_shop: {
                _: '/directory/company/:id/digital-shop',
                name: ':company-name | Digital Shop'
            },
            services: {
                _: '/directory/company/:id/services',
                name: ':company-name | Services'
            },
            media: {
                _: '/directory/company/:id/media',
                name: ':company-name | Media'
            },
            documents: {
                _: '/directory/company/:id/documents',
                name: ':company-name | Documents'
            },
            itp: {
                _: '/directory/company/:id/itp',
                name: ':company-name | ITP',
                new: {
                    _: '/directory/company/:id/itp/new',
                    name: ':company-name | NEW',
                    location: {
                        _: '/directory/company/:id/itp/new/location',
                        name: ':company-name | LOCATION'
                    },
                    target_type: {
                        _: '/directory/company/:id/itp/new/target-type',
                        name: ':company-name | TARGET TYPE'
                    },
                    keywords: {
                        _: '/directory/company/:id/itp/new/keywords',
                        name: ':company-name | KEYWORDS'
                    }
                }
            },
            audit_trail: {
                _: '/directory/company/:id/audit-trail',
                name: 'Audit Trail'
            },
            events: {
                _: '/directory/company/:id/events',
                name: ':company-name | Events'
            },
            advanced_edit: {
                _: '/directory/company/:id/advanced-edit',
                name: 'Advanced Edit'
            },
            wish_campaign: {
                _: '/directory/company/:id/wish_campaign',
                name: 'Wish Campaign'
            }
        }
    },
    match: {
        _: '/dashboard/match',
        name: 'Match',
        wish_list: {
            _: '/dashboard/match/wish-list',
            name: 'Wish List'
        }
    },
    user: {
        _: '/user',
        name: 'user'
    },
    analytics: {
        _: '/analytics',
        companies_statistics: {
            _: '/analytics/companies-statistics'
        },
        call_log_statistics: {
            _: '/analytics/call-log'
        }
    },
    alarms: {
        _: '/alarms',
        messages: {
            _: '/alarms/messages'
        },
        notifications: {
            _: '/alarms/notifications'
        }
    },
    subscription: {
        _: '/subscription',
        name: 'Subscription',
        signup: {
            _: '/subscription/signup',
            name: 'subscription signup',
            email_confirm: {
                _: '/subscription/signup/email-confirm',
                name: 'subscription confirmation'
            }
        },
        login: {
            _: '/subscription/login',
            name: 'lsubscription ogin'
        },
        meeting_hub: {
            _: '/subscription/meeting-hub',
            name: 'MEETING HUB'
        },
        event_hub: {
            _: '/subscription/event-hub',
            name: 'EVENT HUB'
        },
        trade_hub: {
            _: '/subscription/trade-hub',
            name: 'TRADE HUB'
        },
        payment: {
            _: '/subscription/payment',
            name: 'Payment'
        }
    },
    unsubscribe: {
        _: '/unsubscribe',
        name: 'Unsubscribe'
    },
    claim_profile: {
        _: '/claim-profile/:token',
        name: 'Claim Profile'
    }
}
