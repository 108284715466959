// ----------------------------------------------------------------------------------
// Chat Path Connection Config
// ----------------------------------------------------------------------------------
const chatServerProtocol = 'https://'
const chatServerSocketProtocol = 'wss://'
const chatServerDomain = 'chat.aladdinb2b.com'
// const chatServerPort = '443'
const chatServerVersion = 'api/v1'
export const chatServerURL = `${chatServerProtocol}${chatServerDomain}`
export const chatServerURLWithVersion = `${chatServerURL}/${chatServerVersion}`
export const chatServerSocketURL = `${chatServerSocketProtocol}${chatServerDomain}/websocket`
// ----------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------
// End Point Path Connection Config
// ----------------------------------------------------------------------------------
const endpointProtocol = 'https://'
const endpointDomain = 'www.aladdinb2b.com'
const endPointVersion = 'api/v0.3'
export const endpointURL = `${endpointProtocol}${endpointDomain}`
export const endpointURLWithVersion = `${endpointURL}/${endPointVersion}`
export const endpointURLFull = `${endpointURLWithVersion}/`
// ----------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------
// Comman Variable
// ----------------------------------------------------------------------------------
export const defaultPrimaryColor = '#ff681a'
export const defaultLightColor = '#FFF'
export const defaultDarkColor = '#000'

export const listSize = 100
// ----------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------
// Apps Link
// ----------------------------------------------------------------------------------
export const androidLinks = {
    leap: 'https://play.google.com/store/apps/details?id=aladdin.com.leap',
    main: 'https://play.google.com/store/apps/details?id=aladdin.com'
}
export const iosLinks = {
    leap: 'https://apps.apple.com/ae/app/leap-connect-app/id6476942547',
    main: 'https://apps.apple.com/ae/app/aladdinb2b/id1437004305'
}
// ----------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------
// Stripe publish keys config
// ----------------------------------------------------------------------------------
const testingStripePublishKey =
    'pk_test_51JWNrSHQVER0Md7trLW0Tj9qdgsQQT4oAQDn8hTW5jg9KqyZFp0Xf5OdmOKLBROk1fUdLyJT1rbtIqBnoIHehdlQ00Vb0GqnS8'
const productionStripePublishKey =
    'pk_live_51JWNrSHQVER0Md7tK0z9GwU7NFwiDrE6FLflGvgORdtnqbKcHtMMsmIJdZYtAglr3Wt2j4rGWxqu2lCIqN2lSlhR00qJV49vQe'
export const StripePublishKey =
    process.env.NODE_ENV === 'development'
        ? testingStripePublishKey
        : process.env.NODE_ENV === 'production'
        ? productionStripePublishKey
        : testingStripePublishKey

// ----------------------------------------------------------------------------------
// Min Panel config
// ----------------------------------------------------------------------------------
export const mixPanelToken = '8d3f85fd28869b479f4a725e4149896c'
