import { createSlice } from '@reduxjs/toolkit'

export interface IUsersDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    pureFilters?: any
    sort?: string
    projection?: any
    needPopulate: boolean
    addPresignedURL?: boolean
    populates?: any
}

const initialState: IUsersDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    pureFilters: {},
    sort: '-createdAt',
    projection: [],
    needPopulate: true,
    addPresignedURL: true,
    populates: [
        { path: 'role', select: '_id title' },
        { path: 'company', select: '_id name' }
    ]
}

const usersSlice = createSlice({
    name: 'ui/users',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        addPureFilter(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state.pureFilters[key] = action.payload[key]
            }
        },
        onResetFilters(state) {
            state.filters = {}
            state.pureFilters = {}
        },
        onChangeData(state, action) {
            Object.keys(action.payload).map(key => {
                state[key] = action.payload[key]
            })
        }
    }
})

export const usersActions = usersSlice.actions
export default usersSlice.reducer
