import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const generateLinkModal = {
    isOpen: false,
    companyId: '',
    subscriptionId: '',
    orderId: '',
    userId: '',
    userOptionId: '',
    setterOptionId: ''
}
const orderHistorySlice = createSlice({
    name: 'ui/order-history',
    initialState: {
        ...listUIInitialState,
        filters: { status: 'Active' },
        pureFilters: { status: 'Active' },
        visibleChartModal: false,
        populates: ['company', 'subscription', 'users', 'payment'],
        excluded: {},
        subscriptionCardModal: {
            isOpen: false,
            subscriptionId: ''
        },
        generateLinkModal,
        paymentHistoryModal: {
            isOpen: false,
            subscriptionId: ''
        },
        transactionHistoryModal: {
            isOpen: false,
            companyId: ''
        },
        pauseSubscriptionModal: {
            isOpen: false,
            subscriptionId: ''
        },
        cancelSubscriptionModal: {
            isOpen: false,
            subscriptionId: ''
        }
    },
    reducers: {
        ...listUIReducers,
        toggleVisibleChartModal(state) {
            state.visibleChartModal = !state.visibleChartModal
        },
        setPaymentHistoryModalInfo(state, action) {
            Object.keys(action.payload).map(key => {
                state.paymentHistoryModal[key] = action.payload[key]
            })
        },
        setTransactionHistoryModalInfo(state, action) {
            Object.keys(action.payload).map(key => {
                state.transactionHistoryModal[key] = action.payload[key]
            })
        },
        setPauseSubscriptionModalInfo(state, action) {
            Object.keys(action.payload).map(key => {
                state.pauseSubscriptionModal[key] = action.payload[key]
            })
        },
        setCancelSubscriptionModalInfo(state, action) {
            Object.keys(action.payload).map(key => {
                state.cancelSubscriptionModal[key] = action.payload[key]
            })
        },
        setSubscriptionCardModalInfo(state, action) {
            Object.keys(action.payload).map(key => {
                state.subscriptionCardModal[key] = action.payload[key]
            })
        },
        setGenerateLinkModalInfo(state, action) {
            Object.keys(action.payload).map(key => {
                state.generateLinkModal[key] = action.payload[key]
            })
        },
        resetGenerateLinkModal(state) {
            state.generateLinkModal = generateLinkModal
        }
    }
})

export const orderHistoryActions = orderHistorySlice.actions
export default orderHistorySlice.reducer
