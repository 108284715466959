import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import cloneDeep from 'lodash/cloneDeep'
import { isValidMongoId } from '@utils/helpers/commonHelpers'

dayjs.extend(utc)
const prepareCreateFormData = (data, selectedTimes) => {
    const result = cloneDeep(data)
    result.startTime = dayjs(selectedTimes[0].startDate, 'YYYY-MM-DD hh:mm AZ').utc().format()
    result.endTime = dayjs(selectedTimes[0].endDate, 'YYYY-MM-DD hh:mm AZ')
        // .add(result?.meetingDuration || 60, 'minutes')
        .utc()
        .format()
    return result
}

const prepareAddFormData = (data, offsetTime, offset) => {
    const result = cloneDeep(data)
    const startTime = dayjs
        .utc(
            `${result.addDate.format().substr(0, 10)} ${result.addStartTime.get('hour')}:${result.addStartTime.get(
                'minute'
            )}${offsetTime}`,
            'YYYY-MM-DD hh:mm AZ'
        )
        .utcOffset(offset)
    result.startTime = startTime.utc().format()
    result.endTime = startTime.add(result.addDuration, 'minutes').utc().format()
    result.meetingDuration = result.addDuration
    delete result.addDate
    delete result.addDuration
    delete result.addStartTime
    delete result.floorPlanLink
    delete result.bufferTime
    delete result.reminderDuration
    delete result.reminderType
    return result
}

export const prepareFormData = (data, isMeetingHubEvent, formMode, offsetTime, offset, matchList, selectedTimes) => {
    let result = cloneDeep(data)
    if (formMode === 'add') {
        result = prepareAddFormData(result, offsetTime, offset)
    } else {
        result = prepareCreateFormData(result, selectedTimes)
    }
    delete result.selectedTime
    if (!isValidMongoId(result.hallId)) {
        result.place = result.hallId
        delete result.hallId
    }
    if (!isMeetingHubEvent) {
        delete result.timeShift
    } else {
        result.timeShift = dayjs().tz(result.timeShift).utcOffset()
    }
    if (matchList) {
        result.matchList = matchList
    }
    if (formMode === 'edit') {
        if (result.reminderDuration === '') {
            delete result.reminderDuration
        }
    }
    if (result.tableId === '') {
        delete result.tableId
    }
    return result
}
