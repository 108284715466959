import { IFilters } from '@features/General'
import { MeetingStatus, MeetingType } from '@features/Meeting/MeetingAgenda'

export const listFilters = (i18n: any, companyId?: string, isUser = true): IFilters[] => [
    {
        key: 'meetingRequest',
        type: 'tri-state',
        title: i18n?.Dashboard?.['Meetings by operator'] || 'By Operator',
        getQuery: value => {
            if ([true, false].includes(value)) {
                return { $exists: value }
            } else {
                return null
            }
        }
    },
    {
        key: 'companies',
        queryKeys: ['guestCompanyId', 'hostCompanyId'],
        title: i18n?.General?.Companies,
        type: 'select',
        labelField: 'name',
        projection: ['_id', 'name', 'logo'],
        hide: !!companyId,
        searchableFields: ['name'],
        logoField: 'logo',
        multiple: true
    },
    {
        key: 'participants',
        modelName: 'contacts',
        title: i18n?.Meeting?.Contacts,
        type: 'select',
        labelField: ['firstname', 'lastname'],
        searchableFields: ['firstname', 'lastname'],
        queryKeys: ['guestIds', 'hostIds', 'guests'],
        projection: ['_id', 'firstname', 'lastname', 'avatar'],
        logoField: 'avatar',
        multiple: true
    },
    {
        key: 'status',
        title: i18n?.General?.Status,
        type: 'select',
        values: MeetingStatus,
        multiple: true
    },
    {
        key: 'meetingDate',
        queryKeys: ['startTime', 'endTime'],
        title: i18n?.Meeting?.Meeting_Date,
        type: 'rangeDate'
    },
    {
        key: 'createdAt',
        title: i18n?.General?.Creation_Date || 'Creation Date',
        type: 'rangeDate'
    },
    {
        key: 'meetingType',
        title: i18n?.Meeting?.Meeting_Type,
        type: 'select',
        values: MeetingType,
        multiple: true
    },
    {
        key: 'badges',
        title: i18n?.TradeHub?.LabelTag,
        type: 'select',
        modelName: 'labelTag',
        query: { type: { $in: ['Meeting', 'Generic'] } },
        labelField: 'name',
        searchableFields: ['name'],
        multiple: true,
        hide: isUser
    },
    {
        key: 'createdBy',
        modelName: 'contacts',
        title: i18n?.General?.Created_by || 'Created by',
        type: 'select',
        labelField: ['firstname', 'lastname'],
        searchableFields: ['firstname', 'lastname'],
        projection: ['_id', 'firstname', 'lastname', 'avatar'],
        logoField: 'avatar',
        multiple: true
    }
]
