import { createSlice } from '@reduxjs/toolkit'

export interface ICompanyStatisticsDataState {
    pagination: {
        itemsPerPage: number
        currentPage: number
        total: number
    }
    searchValue: string | null
    filters?: any
    pureFilters?: any
    sort?: any
    projection?: any
    needPopulate: boolean
    addPresignedURL: boolean
    populates?: any
}

const initialState: ICompanyStatisticsDataState = {
    pagination: {
        itemsPerPage: 10,
        currentPage: 1,
        total: 0
    },
    searchValue: null,
    filters: {},
    pureFilters: {
        isFirstTimeOnboarding: 'all'
    },
    sort: { meetingsScheduled: -1, meetingsConducted: -1 },
    projection: [
        '_id',
        'RFQs',
        'matches',
        'meetingsConducted',
        'meetingsScheduled',
        'isFirstTimeOnboarding',
        'name',
        'quotations',
        'logo',
        'country',
        'batch',
        'userType'
    ],
    needPopulate: true,
    addPresignedURL: true,
    populates: [{ path: 'country' }, { path: 'batch' }]
}

const companyStatisticsSlice = createSlice({
    name: 'ui/Company-statistics',
    initialState,
    reducers: {
        onChangePagination(state, acion) {
            state.pagination = { ...state.pagination, ...acion.payload }
        },
        onChangeItem(state, action) {
            state[action.payload.key] = action.payload.value
        },
        onChangeSearch(state, action) {
            state.searchValue = action.payload
        },
        onChangeTotal(state, action) {
            state.pagination = { ...state.pagination, total: action.payload }
        },
        addPureFilter(state, action) {
            const objectkeys = Object.keys(action.payload)
            for (const key of objectkeys) {
                state.pureFilters[key] = action.payload[key]
            }
        },
        onResetFilters(state) {
            state.filters = {}
            state.pureFilters = {}
        },
        onChangeData(state, action) {
            Object.keys(action.payload).map(key => {
                state[key] = action.payload[key]
            })
        }
    }
})

export const companyStatisticsActions = companyStatisticsSlice.actions
export default companyStatisticsSlice.reducer
